import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import Twitter from "../../resources/img/ui/icon-twitter-outline.svg"
import Facebook from "../../resources/img/ui/icon-facebook.svg"
import Mail from "../../resources/img/ui/icon-mail.svg"
import ClosingBlock from "./ClosingBlock"

const DisclaimerBlockContainer = styled.div`
  margin: 80px 0 70px;
  border-top: 1px solid ${colors.charcoal};
  padding-top: 15px;
`

const DisclaimerText = styled.div`
  font-size: 13px;
  line-height: 1.46;
`

const Social = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`

const ShareButton = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 25px;
  img {
    width: 100%;
    height: 100%;
  }
  :last-of-type {
    margin-right: 0;
  }
`

const DisclaimerBlock = (props) => {
  const { title, category, slug, closingBlock } = props
  const articleUrl = `${process.env.WEBSITE_URL}/learn/${category}/${slug}`

  let disclaimer =
    " The information contained in this article is for informational purposes only and does not constitute medical advice, nor is it a substitute for professional medical advice, diagnosis or treatment. Always seek the advice of your physician with any questions you may have about the information contained herein, as well as the risks or benefits of any treatment."

  if (closingBlock) {
    if (
      closingBlock.optionalDisclaimerOverride &&
      closingBlock.optionalDisclaimerOverride.childMarkdownRemark.html !== ""
    ) {
      disclaimer =
        closingBlock.optionalDisclaimerOverride.childMarkdownRemark.html
    }
  }

  const shares = [
    {
      icon: Facebook,
      title: "Facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`,
    },
    {
      icon: Twitter,
      title: "Twitter",
      link: `http://twitter.com/share?text=${title}&url=${articleUrl}`,
    },
    {
      icon: Mail,
      title: "Mail",
      link: `mailto:?subject=${title}&body=${articleUrl}`,
    },
  ]

  return (
    <React.Fragment>
      {closingBlock && <ClosingBlock {...closingBlock} />}
      <DisclaimerBlockContainer>
        <DisclaimerText dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <Social>
          {shares.map((media, index) => {
            const { link, icon, title } = media
            return (
              <ShareButton key={index}>
                <a href={link} target={"_blank"}>
                  <img src={icon} alt={title || ""} />
                </a>
              </ShareButton>
            )
          })}
        </Social>
      </DisclaimerBlockContainer>
    </React.Fragment>
  )
}

export default DisclaimerBlock
