import React, { Suspense } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Intro from "../components/Article/Intro"
import Body from "../components/Article/Body"
import Fallback from "../components/Core/Fallback"

const QuizCtaBlock = React.lazy(() => import("../components/Core/QuizCtaBlock"))
const RelatedArticles = React.lazy(() => import("../components/Article/RelatedArticles"))


const ArticleTemplate = (props) => {
  const path = props.location.pathname
  const { page, parentCategory, relatedArticles } = props.data
  const { title, metaTitle, metaDescription, body, socialMediaImage } = page;
  const { cta } = parentCategory;
  const isSSR = typeof window === "undefined"

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  let pageTitle = metaTitle ? metaTitle : title

  const author = page.author;
  let authorType = 'Person';

  if (author && typeof author !== 'undefined') {
    const lowerCaseAuthor = author.toLowerCase();

    if (lowerCaseAuthor.includes('jupiter') || lowerCaseAuthor.includes('team')) {
      authorType = 'Organization'
    }
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": pageTitle,
    "author": {
      "@type": authorType,
      "name": page.author,
    },
  }
  if (page.secondaryCredit && page.secondaryCredit.includes('Julie')) {
    schema.reviewedBy = {
      "@type": "Person",
      "name": "Julie Karen, M.D.",
      "medicalSpecialty": "Dermatology"
    }
  }

  if (page.publishedDate) {
    schema.datePublished = new Date(page.publishedDate).toISOString()
  }

  if (page.updatedDate) {
    schema.dateModified = new Date(page.updatedDate).toISOString()
  }

  return (
    <Layout transparentHeader={true} schema={schema}>
      <Seo
        path={path}
        title={pageTitle}
        description={metaDescription && metaDescription.metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <Intro {...page} />
      <React.Fragment>
        {!isSSR && <Suspense fallback={<Fallback />} />}
        <Body blocks={body} {...page} />
      </React.Fragment>
      {relatedArticles && (
        <React.Fragment>
          {!isSSR && (
            <Suspense fallback={<Fallback />}>
              <RelatedArticles articles={relatedArticles.nodes} />
            </Suspense>
          )}
        </React.Fragment>
      )}
      {cta && (
        <React.Fragment>
          {!isSSR && (
            <Suspense fallback={<Fallback />}>
              <QuizCtaBlock {...cta} plp />
            </Suspense>
          )}
        </React.Fragment>
      )}
    </Layout>
  )
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleTemplateQuery(
    $slug: String!
    $category: String!
    $next: String!
    $previous: String!
  ) {
    page: contentfulPost(slug: { eq: $slug }) {
      id
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      socialMediaImage {
        file {
          url
        }
      }
      author
      secondaryCredit
      featured_image {
        gatsbyImageData(width: 1440)
        file {
          url
        }
      }
      publishedDate(formatString: "MMM. DD, YYYY")
      updatedDate(formatString: "MMM. DD, YYYY")
      category
      slug
      body {
        ... on ContentfulWysiwyg {
          id
          __typename
          wysiwyg {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                title
                contentful_id
                gatsbyImageData(width: 1440)
                file {
                  url
                }
              }
            }
          }
        }
        ... on ContentfulPullQuote {
          id
          __typename
          quoteText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulClosingSection {
          id
          __typename
          productChooser {
            ... on ContentfulProduct {
              __typename
              id
              slug
              title
              product_image_larger {
                gatsbyImageData(width: 840, quality: 90)
              }
              setDescription {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulSet {
              __typename
              id
              bundleImages {
                title
                gatsbyImageData(width: 840, quality: 90)
              }
              slug
              title
              bundleDescription {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          productText {
            childMarkdownRemark {
              html
            }
          }
          optionalDisclaimerOverride {
            optionalDisclaimerOverride
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    parentCategory: contentfulCategory(slug: { eq: $category }) {
      cta {
        ...quizCalloutFragment
      }
    }
    relatedArticles: allContentfulPost(
      filter: { slug: { in: [$previous, $next] } }
    ) {
      nodes {
        ...articleCardFragment
      }
    }
  }
`
