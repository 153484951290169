import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"

const Quote = styled.div`
  ${fonts.garamondItalic};
  font-size: 35px;
  line-height: 1.29;
  margin-bottom: 60px;
  text-align: center;

  a {
    color: ${colors.charcoal};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    line-height: 1.25;
  }
`

const QuoteBlock = (props) => {
  const { quoteText } = props
  return (
    <React.Fragment>
      {quoteText && quoteText.childMarkdownRemark.html !== "" && (
        <Quote
          dangerouslySetInnerHTML={{
            __html: quoteText.childMarkdownRemark.html,
          }}
        />
      )}
    </React.Fragment>
  )
}

export default QuoteBlock
