import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getProductLink, getSetLink } from "../../services/product/link"
import TextButton from "../Core/Buttons/TextButton"

const ClosingBlockContainer = styled.div`
  margin-bottom: 60px;
`

const ClosingSection = styled.div`
  border: 1px solid ${colors.orange};
  padding: 30px;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    align-items: center;
  }
`

const ProductImage = styled(GatsbyImage)`
  height: 260px;

  @media (min-width: ${breakpoints.md}) {
    height: auto;
    max-width: ${(props) => (props.set ? "165px" : "125px")};
    max-height: 180px;
    margin-right: 20px;
  }
`

const ProductContent = styled.div`
  padding-right: 15px;
  @media (min-width: ${breakpoints.md}) {
    padding-right: 0;
  }
`

const Title = styled.div`
  ${fonts.garamondRegular};
  font-size: 26px;
  line-height: 1.2;
`

const Description = styled.div`
  margin-top: 7px;
  font-size: 14px;
  line-height: 1.38;

  a {
    color: inherit;
  }
`

const ButtonContainer = styled.div`
  margin-top: 14px;
`

const ClosingBlock = (props) => {
  const { productChooser, productText } = props
  const { title, slug } = productChooser

  let isSet = false
  let productImage
  let productDescription

  if (productChooser) {
    if (productChooser.__typename === "ContentfulSet") {
      isSet = true
    }
  }

  if (productText && productText.childMarkdownRemark.html !== "") {
    productDescription = productText
  } else {
    const { bundleDescription, setDescription } = productChooser
    if (isSet) {
      productDescription = bundleDescription
    } else {
      productDescription = setDescription
    }
  }

  if (isSet) {
    const { bundleImages } = productChooser
    productImage = bundleImages && bundleImages.length > 0 && bundleImages[0]
  } else {
    const { product_image_larger } = productChooser
    productImage = product_image_larger && product_image_larger
  }

  let link = isSet ? getSetLink(slug) : getProductLink(slug)

  return (
    <ClosingBlockContainer>
      {productChooser && (
        <ClosingSection>
          {productImage && (
            <ProductImage
              image={getImage(productImage)}
              alt={title || ""}
              objectFit={"contain"}
              set={isSet ? 1 : 0}
            />
          )}
          <ProductContent>
            <Title>{title.replace("<br>", "").replace("<br />", "").replace("</br>", "")}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: productDescription.childMarkdownRemark.html,
              }}
            />
            <ButtonContainer>
              <TextButton href={link}>See details</TextButton>
            </ButtonContainer>
          </ProductContent>
        </ClosingSection>
      )}
    </ClosingBlockContainer>
  )
}

export default ClosingBlock
