import React from 'react'
import styled from "styled-components"
import ContentContainer from "../Core/Containers/ContentContainer"
import TextBlock from "./TextBlock"
import QuoteBlock from "./QuoteBlock"
import DisclaimerBlock from "./DisclaimerBlock"

const BodyContainer = styled.div`
  max-width: 720px;
  margin: 30px auto 0;

`

const Body = (props) => {
  const { blocks, title, category, slug } = props;
  let bodyBlocks = [];
  let closingBlock;

  blocks && blocks.forEach((block, index) => {
    const {__typename: type} = block;
    // console.log(type)
    if (type === "ContentfulWysiwyg") {
      bodyBlocks.push(<TextBlock {...block} key={index} />)
    }
    if (type === "ContentfulPullQuote") {
      bodyBlocks.push(<QuoteBlock {...block} key={index} />)
    }
    if (type === "ContentfulClosingSection") {
      closingBlock = block;
    }
  })
  return (
    <ContentContainer>
      <BodyContainer>
        {bodyBlocks}
        <DisclaimerBlock title={title} category={category} slug={slug} closingBlock={closingBlock}/>
      </BodyContainer>
    </ContentContainer>
  )
}

export default Body
