import React, { useState } from "react"
import styled from "styled-components"
import ContentContainer from "../Core/Containers/ContentContainer"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { getCategoryLink } from "../../services/blog/link"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getCategoryTitle } from "../../services/blog/category"
import Pinterest from "../Core/Buttons/Pinterest"

const IntroContainer = styled.div`
  margin-top: 40px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 185px;
    max-width: 720px;
    margin: 185px auto 0;
  }
`

const IntroContent = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.charcoal};
  text-align: center;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  font-size: 40px;
  letter-spacing: -0.3px;
  line-height: 1.1;
  padding-top: 8px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 46px;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 60px;
  }
`

const Author = styled.div`
  ${(props) => (props.only ? fonts.sofiaProBold : fonts.sofiaPro)};
  font-size: 14px;
  line-height: 1.38;
  letter-spacing: normal;
  margin-top: 30px;
  p {
    display: inline-block;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 40px;
  }
`

const Reviewer = styled.div`
  ${fonts.sofiaProBold};
  font-size: 14px;
  line-height: 1.38;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 6px;
`

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vw - 60px);
  margin-top: 45px;
  position: relative;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 65px;
    height: unset;
    padding-bottom: 58.4%;
  }
`

const Date = styled.div`
  font-size: 14px;
  line-height: 1.06;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

const UpdatedAt = styled.div`
  font-size: 14px;
  line-height: 1.06;
  font-style: italic;
  margin-top: 8px;
  max-width: 100px;

  @media (min-width: ${breakpoints.sm}) {
    max-width: none;
  }
`

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  @media (min-width: ${breakpoints.md}) {
    position: absolute !important;
  }
`

const PostMeta = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 14px;
  }
`

const CategoryLink = styled.a`
  ${fonts.sofiaProBold};
  color: ${colors.charcoal};
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.92px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 20px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid ${colors.charcoal};
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  transition: all 0.25s;

  :hover {
    background: ${colors.purple};
    border-color: ${colors.purple};
  }

  @media (min-width: ${breakpoints.md}) {
    letter-spacing: 0;
  }
`

const Intro = (props) => {
  const {
    author,
    secondaryCredit,
    title,
    featured_image,
    publishedDate,
    updatedDate,
    category,
  } = props
  const categoryTitle = getCategoryTitle(category)
  const [showPtSave, setShowPtSave] = useState(false)

  const pinType = "buttonPin"
  const description = `&description=${title}`
  const mediaUrl = `&media=https://${featured_image?.file?.url}`

  const to = `https://www.pinterest.com/pin/create/button/?${mediaUrl}${description}`

  return (
    <ContentContainer>
      <IntroContainer>
        <IntroContent>
          <Title>{title}</Title>
          {author && (
            <Author only={!secondaryCredit ? 1 : 0}>Written by {author}</Author>
          )}
          {secondaryCredit && <Reviewer>{secondaryCredit}</Reviewer>}
          <ImageContainer
            onMouseOver={() => setShowPtSave(true)}
            onMouseLeave={() => setShowPtSave(false)}
            onTouchStart={() => setShowPtSave(true)}
            onTouchEnd={() => setShowPtSave(false)}
          >
            <Pinterest visible={showPtSave} href={to} pinType={pinType} />
            <StyledImage image={getImage(featured_image)} alt={title || ""} loading="eager" />
          </ImageContainer>
        </IntroContent>
        <PostMeta>
          <Date>
            <span>{publishedDate}</span>
            {updatedDate && <UpdatedAt>Last updated: {updatedDate}</UpdatedAt>}
          </Date>
          <CategoryLink href={getCategoryLink(category)}>
            {categoryTitle}
          </CategoryLink>
        </PostMeta>
      </IntroContainer>
    </ContentContainer>
  )
}

export default Intro
